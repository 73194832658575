import { createStore, action, thunk, reducer, persist } from 'easy-peasy';
import accountModel from './account-model';
import authModel from './auth-model';
import optionsModel from './options-model';
import portfolioModel from './portfolio-model';
import privateInvestmentFunds from './private_investment_funds';
import privateStocks from './private_stocks';
import purchasesModel from './purchases-model';
import stocksModel from './stocks-model';
import stocksValuesModel from './stocks-values-model';
import licensesModel from './licenses-model';
import newFeaturesModel from './new-features-model';
import frontpageInfo from './frontpageInfo';
import unmadeSplitTransactions from './unmade_split_transactions-model';
import unmadeDividendTransactions from './unmade_dividend_transactions-model';
import companies from './companies-model';
import portfoliosSummary from './portfolios_summary-model';
import helptexts from './helptexts-model';
import stockMarkets from './stock_markets-model.js';
import portfolioSummary from './portfolio_summary-model.js';
import sectors from './sectors.js';

export const store = createStore({
  account: accountModel,
  auth: authModel,
  options: optionsModel,
  portfolio: persist(portfolioModel, { mergeStrategy: "mergeShallow"}),
  privateInvestmentFunds: privateInvestmentFunds,
  privateStocks: privateStocks,
  purchases: purchasesModel,
  stocks: stocksModel,
  stocksValues: stocksValuesModel,
  licenses: licensesModel,
  newFeatures: newFeaturesModel,
  frontpageInfo: frontpageInfo,
  unmadeSplitTransactions:unmadeSplitTransactions,
  unmadeDividendTransactions:unmadeDividendTransactions,
  companies: companies,
  portfoliosSummary: portfoliosSummary,
  helptexts: helptexts,
  stockMarkets: stockMarkets,
  portfolioSummary: portfolioSummary,
  sectors: sectors,
});
