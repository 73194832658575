import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAppContext }from "contexts/AppContext"
import { useNotification } from "stories/components/Notification"


// Storybook
import { Row, Col, Card,CardBody, Container } from "stories/layout";
import { Input, DatePicker, Checkbox } from "stories/forms";
import { Typography, Button, Link, IconButton, Modal,  } from "stories/components";
import { TableAuto } from "stories/tables"

// App
import { PageWrapper, PageHeader, PageContainer } from "components/Page"
import  { IncomeSummaryTable } from "components/Tables"
import { httpGetTransactions, httpGetPrivateStockTransactions, httpGetIncomeReportSummary, httpGetIncomeReportExcel } from "services/transactions"
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import TransactionTypeSelect from "components/Forms/TransactionTypeSelect/TransactionTypeSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"
import SecuritySelect from "components/Forms/SecuritySelect/SecuritySelect"
import TimeRangeSelect from "components/Forms/TimeRangeSelect/TimeRangeSelect"
import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"
import LicensePayment from "views/vismapay/LicensePayment/LicensePayment"
import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"


import { useHistory } from 'react-router-dom';




import { useAuthContext } from "contexts/AuthContext"

import { getDate } from "services/datetime"
import { roundScaleDecimals, thousandFormat } from "services/helpers"
import { set } from "date-fns";
import { filterEventStoreDefs } from "@fullcalendar/core";

import Info from "components/Info/Info.js";
import { setIn } from "formik";
import filter from "list.js/src/filter";


const IncomeReportSummary = ({match, setExportButton}) => {
  
  const { t } = useTranslation();
  const { enums, getSecurityType } = useAppContext()
  const account = useStoreState((state) => state.account?.account);
  //use history to redirect to frontpage if no portfolio
  const history = useHistory();

  const { getTransactionType, getPortfolioName,getPortfolioCurrency} = useAppContext()
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
   
  const purchases = useStoreState((state) => state.purchases?.objects);
  const setPurchases = useStoreActions((actions) => actions.purchases.setObjects);
  const count = useStoreState((state) => state.purchases?.count);
  const setCount = useStoreActions((actions) => actions.purchases.setCount);
  
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [profitSummary, setProfitSummary] = useState();
  const [securityOptions, setSecurityOptions] = useState();
  const [onlySummary, setOnlySummary] = useState(true);

  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfoliosLoading = useStoreState((state) => state.portfolio?.loading);
  const { notify } = useNotification();
  
  const handleDateChange = event => {
    setCurrentDate(event.target.value)
  }
  
  // Table
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState([])



  const [filters, setFilters] = useState({
    // limit: 50,
    // offset:0,
    ordering: "-date_transaction",
    // stock_market: "XHEL",
    portfolio: [portfolio?.id],
    selected_currency: portfolio?.currency_label,
    selected_portfolios: [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}],
    time_range: JSON.stringify({
      key: "current_year",
      value: t("current_year", "Kuluva vuosi"),
      range: {
        start: new Date(
            new Date().getFullYear(), 
            0, 
            1),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      },
    }),
    date_transaction__date__gte: new Date( new Date().getFullYear(), 0, 1),
    date_transaction__date__lte:new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
  })

  useEffect(() => {
    if (portfolio?.id){
      setFilters(s => ({
        ...s,
        portfolio: [portfolio?.id],
        selected_currency: portfolio?.currency_label,
        selected_portfolios: [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}],
      }))
    }
    getTransactions([portfolio?.id], portfolio?.currency_label, [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}])

  }, [portfolio?.id])


  const getTransactions = (p,c,sp) => {
    // let portfolio = [];
    // if (filters.portfolio) {
    //   portfolio = filters.portfolio.map(item => {
    //       console.log(item)
    //       return {
    //           portfolio: item?.id ? item?.id: item
    //       }
    // })  
    // }
    const params = {
      ...filters,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      selected_portfolios: null,
      portfolio: p? p: filters?.portfolio,
      selected_currency: c?c:filters?.selected_currency,
    }
    setLoading(true)

    if(filters?.stock_market === "PRIVATE") {
      httpGetPrivateStockTransactions(params).then(response => {
        setPurchases(response?.data?.results)
        setCount(response?.data?.count)
        setLoading(false)
  
      })
    
    } else {

      // httpGetTransactions(params).then(res => {

      //   setPurchases(res?.data?.results)
      //   setCount(res?.data?.count)

      //     setLoading(false)
        
      // })

      httpGetIncomeReportSummary(params).then(response => {
        setProfitSummary(response?.data)

        if (response?.data){
          let options=  []
          const sellOptions = response?.data?.sell || []
          const buyOptions = response?.data?.buy || []
          const optionsSet = new Set()
        
          // Add unique sell items to the options set
          sellOptions.forEach(item => {
            const key = `${item.related_model_type}-${item.related_model_id}`
            if (!optionsSet.has(key)) {
              optionsSet.add(key)
              options.push({related_model_type: item?.related_model_type, related_model_id: item?.related_model_id, value:item?.related_model?.[0]?.name})
            }
          })
        
          // Add unique buy items to the options set
          buyOptions.forEach(item => {
            const key = `${item.related_model_type}-${item.related_model_id}`
            if (!optionsSet.has(key)) {
              optionsSet.add(key)
              options.push({related_model_type: item?.related_model_type, related_model_id: item?.related_model_id, value:item?.related_model?.[0]?.name})
            }
          })

          setSecurityOptions(options)
        }
  
        // console.log(response)
        setLoading(false)
      })

    }

    
  }
  
  const getSecurities = () => {


    const params = {
      portfolio: filters?.portfolio,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      selected_portfolios: null,
      ordering: filters?.ordering
    }


      httpGetIncomeReportSummary(params).then(response => {
        // setProfitSummary(response?.data)

        if (response?.data){
          let options=  []
          const sellOptions = response?.data?.sell || []
          const buyOptions = response?.data?.buy || []
          const optionsSet = new Set()
        
          // Add unique sell items to the options set
          sellOptions.forEach(item => {
            const key = `${item.related_model_type}-${item.related_model_id}`
            if (!optionsSet.has(key)) {
              optionsSet.add(key)
              options.push({related_model_type: item?.related_model_type, related_model_id: item?.related_model_id, value:item?.related_model?.[0]?.name})
            }
          })
        
          // Add unique buy items to the options set
          buyOptions.forEach(item => {
            const key = `${item.related_model_type}-${item.related_model_id}`
            if (!optionsSet.has(key)) {
              optionsSet.add(key)
              options.push({related_model_type: item?.related_model_type, related_model_id: item?.related_model_id, value:item?.related_model?.[0]?.name})
            }
          })

          setSecurityOptions(options)
        }
        // console.log(response)
        setLoading(false)
      })


    
  }


  // useEffect(() => {
  //   // getSecurities()
  //   setFilters(s => ({
  //     ...s,
  //     stock_market: undefined,
  //     related_model_id: undefined,
  //     related_model_type: undefined
  //   }))
  // }, [filters?.portfolio])
  
  useEffect(() => {
    // getTransactions()

  }, [filters?.limit, filters?.offset, filters?.ordering,  ])

  useEffect(() => {
    // getSecurities()

  }, [filters?.related_model_type,  ])


// }, [filters?.limit, filters?.offset, filters?.ordering, filters?.portfolio, filters?.related_model_type,filters?.related_model_id, filters?.stock_market, filters?.date_transaction__date__gte, filters?.date_transaction__date__lte, filters?.type ])
  
  //[...filters, remove:undefined]

  useEffect(() => {
    const selectedPortfolioIds = [];
    let currency = portfolio?.currency_label



    filters.selected_portfolios.forEach(item => selectedPortfolioIds.push(item.id));

    if (filters.selected_portfolios && filters.selected_portfolios.length == 1){
      //filter portfolios by selected portfolio
      const selectedPortfolio = portfolios.filter(item => item.id === filters.selected_portfolios[0]?.id)
      currency = selectedPortfolio[0]?.currency_label
    }
    if (filters.selected_portfolios && filters.selected_portfolios.length > 1){
      currency = "EUR"
    }
    setFilters(s => ({
      ...s,
      portfolio: selectedPortfolioIds?selectedPortfolioIds:[portfolio?.id],
      selected_currency: currency
    }))
  }, [filters.selected_portfolios])


  // Excel export BEGIN

  const [loadingExport, setLoadingExport] = useState(false)

  const handleExcelExport = (file_type="excel") => {
    

    const params = {
      ...filters,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      selected_portfolios: null,
      export_excel: true,
      client_timezone_offset: Intl.DateTimeFormat().resolvedOptions().timeZone,
      file_type: file_type,
      only_summary: onlySummary,
    }

    let fileName= "investime_income_report_export.xlsx";

    if (file_type === "pdf"){
      fileName = "investime_income_report_export.pdf";
    }
    

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    
    setLoadingExport(true)
    if(file_type === "pdf"){
      notify({ title:t("Viedään PDF..."), icon: "fas fa-file-export"})
    }
    else{
      notify({ title:t("Viedään Exceliin..."), icon: "fas fa-file-export"})
    }
    httpGetIncomeReportExcel(params).then(response => {
      let file = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      if (file_type === "pdf"){
        file = new Blob([response.data], { type: 'application/pdf' });
      }
      let fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = fileName;
      a.click();
    })
    .finally(() => {
      setLoadingExport(false)
    })
  }
  //END



  // LICENSE update and PAYMENT BEGIN

  const openUpdateUp = useCallback(() => {
    return (<>{(account?.license?.name == "Lite") && <UpdateUp redirectToFrontpage={true}/>}</>)
  }, [account?.license])

  // END




  const SummaryHeaders = [
    { label: t("income_report_summary_date", "Päivä"), key: "date_transaction", sortable: false, },

    { label: t("income_report_summary_batch_id", "Erän ID"), key: "batch_id", sortable: false},
    { label: t("income_report_summary_label", "Tunnus"), key: "ticker_symbol", sortable: false},

    // { label: t("income_report_summary_label", "Tunnus"), key: "stock__ticker_symbol", sortable: false},
    { label: t("income_report_summary_name", "Nimi (kpl)"), key: "stock__name", sortable: false},
    // { label: t("income_report_summary_balance", "Määrä"), key: "quantity"},
    { label: t("income_report_summary_portfolio", "Salkku"), key: "portfolio"},
    { label: t("income_report_summary_currency", "Valuutta"), key: "currency"},

    // { label: t("income_report_summary_quantity", "Määrä"), key: "quantity", sortable: false},
    { label: t("income_report_summary_purchase_cost", "Hankintahinta"), key: "purchase_cost", sortable: false},
    { label: t("income_report_summary_price_total", "Myynti-/markkinahinta"), key: "price_total", sortable: false},
    { label: t("income_report_summary_commission_total", "Kulut"), key: "commission_total", sortable: false},
    { label: t("income_report_summary_profit", "Tuotto"), key: "profit", sortable: false},
    // { label: t("income_report_summary_actions", ""), key: "actions"},
  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  

  

  const getSummaryTableRows = useCallback((type) => {

    let rows = []

    let buy_total_purchase_cost = 0
    let buy_total_price_summary = 0
    let buy_commission_total = 0
    let buy_value_total = 0
    let buy_profit = 0


    let sell_total_purchase_cost = 0
    let sell_total_price_summary = 0
    let sell_commission_total = 0
    let sell_profit = 0

    let dividend_value_total = 0
    let dividend_quantity_total = 0
    let dividend_profit = 0

    let other_total_purchase_cost = 0
    let other_total_price_summary = 0
    let other_commission_total = 0
    let other_value_total = 0
    let other_profit = 0

    //Summary per security
    let security_dividend_value_total = 0
    let security_dividend_quantity_total = 0
    let security_dividend_profit = 0

    let security_other_value_total = 0
    let security_other_profit = 0
    let security_other_quantity_total = 0


    let security_buy_total_purchase_cost = 0
    let security_buy_total_price_summary = 0
    let security_buy_commission_total = 0
    let security_buy_value_total = 0

    let security_buy_profit = 0
    let security_buy_quantity = 0


    let security_sell_total_purchase_cost = 0
    let security_sell_total_price_summary = 0
    let security_sell_commission_total = 0
    let security_sell_profit = 0
    let security_sell_quantity = 0

    //Keep track of current security
    let current_security = null
    
    //Sort profitSummary by row.related_model.name
    if (profitSummary?.sell?.length > 0) {
      profitSummary?.sell.sort((a, b) => {
        if (a?.related_model?.[0]?.name < b?.related_model?.[0]?.name) {
          return -1;
        }
        if (a?.related_model?.[0]?.name > b?.related_model?.[0]?.name) {
          return 1;
        }
        return 0;
      })
    }
    if (profitSummary?.buy?.length > 0) {
      profitSummary?.buy.sort((a, b) => {
        if (a?.related_model?.[0]?.name < b?.related_model?.[0]?.name) {
          return -1;
        }
        if (a?.related_model?.[0]?.name > b?.related_model?.[0]?.name) {
          return 1;
        }
        return 0;
      })
    }
    if (profitSummary?.dividend?.length > 0) {
      profitSummary?.dividend.sort((a, b) => {
        if (a?.related_model?.[0]?.name < b?.related_model?.[0]?.name) {
          return -1;
        }
        if (a?.related_model?.[0]?.name > b?.related_model?.[0]?.name) {
          return 1;
        }
        return 0;
      })
    }

    if (profitSummary?.other?.length > 0) {
      profitSummary?.other.sort((a, b) => {
        if (a?.related_model?.[0]?.name < b?.related_model?.[0]?.name) {
          return -1;
        }
        if (a?.related_model?.[0]?.name > b?.related_model?.[0]?.name) {
          return 1;
        }
        return 0;
      })
    }






    //--------SELL Rows -----
    if(type === "all" || type === "sell" ||type==="summary"){

      //data rows
      profitSummary?.sell && profitSummary?.sell.map((row) => {
        

        sell_total_purchase_cost += Number(row?.purchase_cost)
        sell_total_price_summary += Number(row?.price_total)
        sell_commission_total += Number(row?.commission_total)
        sell_profit += row?.commission_total+ Number(row?.price_total) + Number(row?.purchase_cost)
        
        row.profit = row?.commission_total + Number(row?.price_total) + Number(row?.purchase_cost)


        // add security summary row and calculate security values 
        if (row?.related_model?.[0]?.name !== current_security && (type==="sell" || type==="all") ) {
          if (current_security !== null) {
            //summary row
            rows.push({
              date_transaction: t("income_report_total", 'Yhteensä'),
              purchase_cost: roundScaleDecimals(security_sell_total_purchase_cost, 2),
              // related_model: {name:summary_name_sell},
              // total_quantity: sell_total_purchase_cost,
              price_total: roundScaleDecimals(security_sell_total_price_summary, 2),
              commission_total: roundScaleDecimals(security_sell_commission_total, 2),
              stock__name: current_security,
              profit: roundScaleDecimals(security_sell_profit, 2),
              quantity: roundScaleDecimals(security_sell_quantity, 2),
              related_model: [{name:current_security}],
              type: "summary",
              second_type: "security_summary"
              // className: "bg-light"
            })
            // empty line
            profitSummary?.sell.length > 0 && !onlySummary && rows.push({
              type: "space",
              date_transaction: "empty line"
            })
          }

          current_security = row?.related_model?.[0]?.name
          security_sell_total_purchase_cost = 0
          security_sell_total_price_summary = 0
          security_sell_commission_total = 0
          security_sell_profit = 0
          security_sell_quantity = 0

        }
        security_sell_total_purchase_cost += Number(row?.purchase_cost)
        security_sell_total_price_summary += Number(row?.price_total)
        security_sell_commission_total += Number(row?.commission_total)
        security_sell_profit += row?.commission_total+ Number(row?.price_total) + Number(row?.purchase_cost)
        security_sell_quantity += Number(row?.quantity)
        if((type==="sell" || type==="all") && !onlySummary) {
          rows.push(row)
        }

        //if last row add security summary row
        if (row === profitSummary?.sell[profitSummary?.sell.length - 1] && (type==="sell" || type==="all")) {
          rows.push({
            date_transaction: t("income_report_total",'Yhteensä'),
            purchase_cost: roundScaleDecimals(security_sell_total_purchase_cost, 2),
            // related_model: {name:summary_name_sell},
            // total_quantity: sell_total_purchase_cost,
            price_total: roundScaleDecimals(security_sell_total_price_summary, 2),
            commission_total: roundScaleDecimals(security_sell_commission_total, 2),
            stock__name: current_security,
            profit: roundScaleDecimals(security_sell_profit, 2),
            quantity: roundScaleDecimals(security_sell_quantity, 2),
            related_model: [{name:current_security}],
            type: "summary",
            second_type: "security_summary"
            // className: "bg-light"
          })
          // empty line
          profitSummary?.sell.length > 0 && rows.push({
            type: "space",
            date_transaction: "empty line"
          })
        }

      } ) 

      if(type==="sell" || type==="all"){

        //summary row
        profitSummary?.sell.length > 0 && rows.push({
              date_transaction: t("income_report_realized_total","Realisoituneet yhteensä"),
              purchase_cost: roundScaleDecimals(sell_total_purchase_cost, 2),
              // related_model: {name:summary_name_sell},
              // total_quantity: sell_total_purchase_cost,
              price_total: roundScaleDecimals(sell_total_price_summary, 2),
              commission_total: roundScaleDecimals(sell_commission_total, 2),
              profit: roundScaleDecimals(sell_profit, 2),
              type: "summary",

              className: "bg-light"
            })
      }
      //empty line
      // profitSummary?.sell.length > 0 && rows.push({
      //   type: "space",
      //   date_transaction: "empty line"
      // })
  }


  //BUY
  if (type === "all" || type === "buy" || type==="summary"){

    //Data rows
    profitSummary?.buy && profitSummary?.buy.map((row) => {
      // console.log(row)
       // add security summary row and calculate security values 
       if (row?.related_model?.[0]?.name !== current_security && (type==="buy" || type==="all") ) {
        if (current_security !== null) {
          //summary row
          rows.push({
            date_transaction: t("income_report_total",'Yhteensä'),
            purchase_cost: roundScaleDecimals(security_buy_total_purchase_cost, 2),
            // related_model: {name:summary_name_sell},
            // total_quantity: sell_total_purchase_cost,
            price_total: roundScaleDecimals(security_buy_total_price_summary, 2),
            commission_total: roundScaleDecimals(security_buy_commission_total, 2),
            stock__name: current_security,
            profit: roundScaleDecimals(security_buy_profit, 2),
            quantity: roundScaleDecimals(security_buy_quantity, 2),
            related_model: [{name:current_security}],
            type: "summary",
            second_type: "security_summary"
            // className: "bg-light"
          })
          // empty line
          profitSummary?.buy.length > 0 && !onlySummary && rows.push({
            type: "space",
            date_transaction: "empty line"
          })
        }

        current_security = row?.related_model?.[0]?.name
        security_buy_total_purchase_cost = 0
        security_buy_total_price_summary = 0
        security_buy_commission_total = 0
        security_buy_profit = 0
        security_buy_quantity = 0

      }
      security_buy_total_purchase_cost += Number(row?.purchase_cost)
      security_buy_commission_total += Number(row?.commission_total)
      security_buy_quantity += Number(row?.batch_balance)

      buy_total_purchase_cost += Number(row?.purchase_cost)
      buy_commission_total += Number(row?.commission_total)



      if (row?.related_model?.[0]?.ending_price){
        buy_total_price_summary += Number(row?.batch_balance) * Number(row?.related_model?.[0]?.ending_price)
        buy_profit += Number(row?.batch_balance) * Number(row?.related_model?.[0]?.ending_price) + Number(row?.commission_total) + Number(row?.purchase_cost)
        row.profit = Number(row?.batch_balance) * Number(row?.related_model?.[0]?.ending_price) + Number(row?.commission_total) + Number(row?.purchase_cost)

        row.price_total =Number(row?.batch_balance) * Number(row?.related_model?.[0]?.ending_price)

        security_buy_total_price_summary += Number(row?.batch_balance) * Number(row?.related_model?.[0]?.ending_price)
        security_buy_profit += Number(row?.batch_balance) * Number(row?.related_model?.[0]?.ending_price) + Number(row?.commission_total) + Number(row?.purchase_cost)
        
      }
      else{
        // row.profit =t('Ei saatavilla')
        // row.price_total =t('Ei saatavilla')

        // let calc_price = Math.abs(Number(row?.price_total)) / row?.original_batch_balance

        row.price_total = Math.abs(Number(row?.purchase_cost))
        
        row.profit = row.price_total + Number(row?.commission_total) + Number(row?.purchase_cost)
        security_buy_total_price_summary += row.price_total
        security_buy_profit += row.price_total + Number(row?.commission_total) + Number(row?.purchase_cost)
        buy_total_price_summary += row.price_total
        buy_profit += row.price_total + Number(row?.commission_total) + Number(row?.purchase_cost)


        // row.profit = Number(row?.batch_balance) * row.price + Number(row?.commission_total) + Number(row?.purchase_cost)
        // row.price_total =Number(row?.batch_balance) * row.price
        // security_buy_total_price_summary += Number(row?.batch_balance) * row.price
        // security_buy_profit += Number(row?.batch_balance) * (row.price) + Number(row?.commission_total) + Number(row?.purchase_cost)
        // buy_total_price_summary += Number(row?.batch_balance) * row.price
        // buy_profit += Number(row?.batch_balance) * (row.price) + Number(row?.commission_total) + Number(row?.purchase_cost)
      }
      row.commission_total = row?.commission_total == 0 ? roundScaleDecimals(Number("0.00"), 2) : row?.commission_total
      row.quantity= Number(row?.batch_balance)
      row.batch_id = row?.id
      if ((type === "all" || type === "buy") && !onlySummary){
        rows.push(row)
      }

      //if last row add security summary row
      if (row === profitSummary?.buy[profitSummary?.buy.length - 1] && (type==="buy" || type==="all")) {
        rows.push({
          date_transaction: t("income_report_total",'Yhteensä'),
          purchase_cost: roundScaleDecimals(security_buy_total_purchase_cost, 2),
          // related_model: {name:summary_name_sell},
          // total_quantity: sell_total_purchase_cost,
          price_total: roundScaleDecimals(security_buy_total_price_summary, 2),
          commission_total: roundScaleDecimals(security_buy_commission_total, 2),
          stock__name: current_security,
          profit: roundScaleDecimals(security_buy_profit, 2),
          quantity: roundScaleDecimals(security_buy_quantity, 2),
          related_model: [{name:current_security}],
          type: "summary",
          second_type: "security_summary"
          // className: "bg-light"
        })
        // empty line
        profitSummary?.buy.length > 0 && rows.push({
          type: "space",
          date_transaction: "empty line"
        })

      }
    } ) 

    if (type === "all" || type === "buy"){
    //Summary row
    profitSummary?.buy.length >0 && rows.push({
      date_transaction: t("income_report_unrealized_total","Realisoitumattomat yhteensä"),
      purchase_cost: roundScaleDecimals(buy_total_purchase_cost,2),
      price_total: roundScaleDecimals(buy_total_price_summary, 2),
      commission_total: roundScaleDecimals(buy_commission_total, 2),
      profit: roundScaleDecimals(buy_profit, 2),
      value_total: roundScaleDecimals(buy_value_total, 2),
      type: "summary",
      className: "bg-light",
    })
   }
    //Empty line
    // profitSummary?.buy.length > 0 && rows.push({
    //   type: "space",
    //   date_transaction: "empty line"
    // })
  }


    //DIVIDEND
    if(type === "all" || type === "dividend" ||type==="summary"){

      //data rows
      profitSummary?.dividend && profitSummary?.dividend.map((row) => {
        
        dividend_value_total += Number(row?.price_total)
        dividend_quantity_total += Number(row?.quantity)
        // dividend_total_purchase_cost += Number(row?.purchase_cost)
        // dividend_total_price_summary += Number(row?.price_total)
        // dividend_commission_total += Number(row?.commission_total)
        dividend_profit +=  Number(row?.price_total)
        // row.profit = row?.commission_total + Number(row?.price_total) + Number(row?.purchase_cost)


        // add security summary row and calculate security values 
        if (row?.related_model?.[0]?.name !== current_security && (type==="dividend" || type==="all") ) {
          if (current_security !== null) {
            //summary row
            rows.push({
              date_transaction: t('Yhteensä'),
              // purchase_cost: roundScaleDecimals(security_dividend_total_purchase_cost, 2),
              // related_model: {name:summary_name_dividend},
              // total_quantity: dividend_total_purchase_cost,
              price_total: roundScaleDecimals(security_dividend_value_total, 2),
              // commission_total: roundScaleDecimals(security_dividend_commission_total, 2),
              stock__name: current_security,
              profit: roundScaleDecimals(security_dividend_profit, 2),
              quantity: roundScaleDecimals(security_dividend_quantity_total, 2),
              related_model: [{name:current_security}],
              type: "summary",
              second_type: "security_summary"
              // className: "bg-light"
            })
            // empty line
            profitSummary?.dividend.length > 0 && !onlySummary && rows.push({
              type: "space",
              date_transaction: "empty line"
            })
          }

          current_security = row?.related_model?.[0]?.name
          security_dividend_quantity_total = 0
          security_dividend_value_total = 0
          security_dividend_profit = 0
          // security_dividend_quantity_total = 0

          // security_dividend_total_purchase_cost = 0
          // security_dividend_total_price_summary = 0
          // security_dividend_commission_total = 0

        }
        // security_dividend_total_purchase_cost += Number(row?.purchase_cost)
        // security_dividend_total_price_summary += Number(row?.price_total)
        // security_dividend_commission_total += Number(row?.commission_total)
        security_dividend_profit += Number(row?.price_total)
        security_dividend_quantity_total += Number(row?.quantity)
        security_dividend_value_total += Number(row?.price_total)
        if((type==="dividend" || type==="all") && !onlySummary) {
          rows.push(row)
        }

        //if last row add security summary row
        if (row === profitSummary?.dividend[profitSummary?.dividend.length - 1] && (type==="dividend" || type==="all")) {
          rows.push({
            date_transaction: t('Yhteensä'),
            // purchase_cost: roundScaleDecimals(security_dividend_total_purchase_cost, 2),
            // related_model: {name:summary_name_dividend},
            // total_quantity: dividend_total_purchase_cost,
            price_total: roundScaleDecimals(security_dividend_value_total, 2),
            // commission_total: roundScaleDecimals(security_dividend_commission_total, 2),
            stock__name: current_security,
            profit: roundScaleDecimals(security_dividend_profit, 2),
            quantity: roundScaleDecimals(security_dividend_quantity_total, 2),
            related_model: [{name:current_security}],
            type: "summary",
            second_type: "security_summary"
            // className: "bg-light"
          })
          // empty line
          profitSummary?.dividend.length > 0 &&  rows.push({
            type: "space",
            date_transaction: "empty line"
          })
        }

      } ) 

      if(type==="dividend" || type==="all"){

        //summary row
        profitSummary?.dividend.length > 0 && rows.push({
              date_transaction: t("income_report_dividends_total","Osingot yhteensä"),
              // purchase_cost: roundScaleDecimals(dividend_total_purchase_cost, 2),
              // related_model: {name:summary_name_dividend},
              // total_quantity: dividend_total_purchase_cost,
              price_total: roundScaleDecimals(dividend_value_total, 2),
              // commission_total: roundScaleDecimals(dividend_commission_total, 2),
              profit: roundScaleDecimals(dividend_profit, 2),
              type: "summary",

              className: "bg-light"
            })
      }
      //empty line
      // profitSummary?.sell.length > 0 && rows.push({
      //   type: "space",
      //   date_transaction: "empty line"
      // })
  }


      //Other
      if(type === "all" || type === "other" ||type==="summary"){
        //data rows
        profitSummary?.other && profitSummary?.other.map((row) => {
          other_value_total += Number(row?.price_total)
          other_profit += Number(row?.price_total)
          



          // add security summary row and calculate security values
          if (row?.related_model?.[0]?.name !== current_security && (type==="other" || type==="all") ) {
            if (current_security !== null) {
              //summary row
              rows.push({
                date_transaction: t('Yhteensä'),
                // purchase_cost: roundScaleDecimals(security_other_total_purchase_cost, 2),
                // related_model: {name:summary_name_other},
                // total_quantity: other_total_purchase_cost,
                price_total: roundScaleDecimals(security_other_value_total, 2),
                // commission_total: roundScaleDecimals(security_other_commission_total, 2),
                quantity: roundScaleDecimals(security_other_quantity_total, 2),
                stock__name: current_security,
                profit: roundScaleDecimals(security_other_profit, 2),
                related_model: [{name:current_security}],
                type: "summary",
                second_type: "security_summary"
                // className: "bg-light"
              })
              // empty line
              profitSummary?.other.length > 0 && !onlySummary && rows.push({
                type: "space",
                date_transaction: "empty line"
              })
            }

            current_security = row?.related_model?.[0]?.name
            security_other_value_total = 0
            security_other_profit = 0
            security_other_quantity_total = 0

          }

          security_other_value_total += Number(row?.price_total)
          security_other_profit += Number(row?.price_total)
          security_other_quantity_total += Number(row?.quantity) 


          if ((type==="other" || type==="all") && !onlySummary) {
            rows.push(row)
          }

          if (row === profitSummary?.other[profitSummary?.other.length - 1] && (type==="other" || type==="all")) {
            rows.push({
              date_transaction: t('Yhteensä'),
              // purchase_cost: roundScaleDecimals(security_other_total_purchase_cost, 2),
              // related_model: {name:summary_name_other},
              // total_quantity: other_total_purchase_cost,
              price_total: roundScaleDecimals(security_other_value_total, 2),
              // commission_total: roundScaleDecimals(security_other_commission_total, 2),
              stock__name: current_security,
              profit: roundScaleDecimals(security_other_profit, 2),
              related_model: [{name:current_security}],
              quantity: roundScaleDecimals(security_other_quantity_total, 2),

              type: "summary",
              second_type: "security_summary"
              // className: "bg-light"
            })
            // empty line
            profitSummary?.other.length > 0 && rows.push({
              type: "space",
              date_transaction: "empty line"
            })
          }
        })

        if(type==="other" || type==="all"){
          //summary row
          profitSummary?.other.length > 0 && rows.push({
                date_transaction: t("income_report_other_total","Muut yhteensä"),
                // purchase_cost: roundScaleDecimals(other_total_purchase_cost, 2),
                price_total: roundScaleDecimals(other_value_total, 2),
                // commission_total: roundScaleDecimals(other_commission_total, 2),
                profit: roundScaleDecimals(other_profit, 2),
                type: "summary",
                className: "bg-light"
              })
        }



      
      }



  //SUMMARY
  if (type==="all" || type==="summary"){

    profitSummary && rows.push({
      date_transaction: t("income_report_buy_sell_total","Ostot ja myynnit yht."),
      purchase_cost: roundScaleDecimals(buy_total_purchase_cost +sell_total_purchase_cost,2) ,
      profit: roundScaleDecimals(buy_profit +sell_profit, 2),
      price_total: roundScaleDecimals(buy_total_price_summary + sell_total_price_summary, 2),
      commission_total: roundScaleDecimals(buy_commission_total + sell_commission_total, 2),
      value_total: roundScaleDecimals(buy_value_total +sell_profit, 2),
      type: "summary",
      className: "bg-light"
    })
  }
    


    return profitSummary && rows.map((row, index) => {
      const transactionTypeValue = row?.type
      let transactionTypeCell;
      if (transactionTypeValue == "2") {
        transactionTypeCell = (<span className="text-success font-weight-bold">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue == "3") {
        transactionTypeCell = (<span className="text-danger font-weight-bold">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else {
        transactionTypeCell = (<span className="text-info font-weight-bold"></span>)
      }
      const stockNameCell = (
        <><span className={row?.type== "summary" ? "font-weight-bolder": null} style={{opacity: row?.type == "space" ? 0 : 1}}>{row?.type !="summary" || row?.second_type =="security_summary" ?`${row?.related_model?.[0]?.name} (${thousandFormat(roundScaleDecimals(row?.quantity,2))})`:null}</span></>
      )
      const comissionTotalCell = (
        <><span className={row?.type== "summary" ? "font-weight-bold": null}>{!row?.commission_total? "": thousandFormat(roundScaleDecimals(row?.commission_total, 2))}</span></>
      )
      const profitCell = (
        <span className={row?.type== "summary" ? "font-weight-bolder": null}>
          {/\d/.test(row?.profit)? (
            <span className={row.profit < 0 ? "text-danger" : "text-success"}>
            {thousandFormat(roundScaleDecimals((row?.profit), 2))}
            </span>
          ):(
            <span className={ "text-danger"}>
            {row?.profit}
            </span>
          )
          }
        </span>
        
      )
      const priceTotalCell = (
        <span className={row?.type== "summary" ? "font-weight-bold": null}>
          {/\d/.test(row?.price_total)? (
            <span >
            {thousandFormat(roundScaleDecimals((row?.price_total), 2))}
          
            {type=="buy" && row?.type!="summary" && <span className="text-gray ml-2" > ({row?.related_model?.[0]?.stock_date? getDate(row?.related_model?.[0]?.stock_date): getDate(row?.date_transaction) })**</span>}
            </span>
          ):(
            <span className={ "text-danger"}>
            {row?.price_total}
            </span>
          )
          }
        </span>
      )
      const purchaseCostCell = (
        <><span className={row?.type== "summary" ? "font-weight-bold": row?.type =="space"? "pt-3":null} >{!row?.purchase_cost ? "":thousandFormat(roundScaleDecimals(row?.purchase_cost,2))}</span></>

      )      
      const dateTransactionCell = (
        <>
          <div
            className={row?.type== "summary" ? "font-weight-bold": row?.type =="space"? "pt-3":null} 
            style={{opacity: row?.type == "space" ? 0 : 1,  }}
          >
          {
            /\d/.test(row?.date_transaction)? 
            <>
            {row?.original_purchase_date? <> {getDate(row?.original_purchase_date) + " " }<span className="text-gray ml-1 ">({getDate(row?.date_transaction)})*</span></>:getDate(row?.date_transaction)}
            </>: 
             <div style={{ maxWidth: '140px', whiteSpace: 'pre-wrap',overflowWrap: 'break-word'  }}>         
          {row?.original_purchase_date? <> {getDate(row?.original_purchase_date) + " " }<span className="text-gray ">({getDate(row?.date_transaction)}*)</span></>:row?.date_transaction}
          </div>
          }
          </div>
        </>


      )
      return {
        ...row,
        date_transaction: dateTransactionCell,
        ticker_symbol: row?.related_model?.[0]?.ticker_symbol,
        stock__name: stockNameCell,
        // currency:  row?.type != "space" && <span>{filters?.portfolio.length > 1? "EUR": getPortfolioCurrency(filters?.portfolio[0])}</span>,
        currency:  row?.type != "space" && <span>{filters?.selected_currency}</span>,
        portfolio: getPortfolioName(row?.portfolio),
        // purchase_cost: purchaseCostCell,
        purchase_cost: {
          content: purchaseCostCell,
          className: "text-right"
        },
        price_total: {
          content: priceTotalCell,
          className: "text-right"
        },
        commission_total: {
          content: comissionTotalCell,
          className: "text-right"
        },
        profit: {
          content: profitCell,
          className: "text-right"
        },

        type: transactionTypeCell,
        stock_market: row?.stock_market,
        actions:(
                    <><Button className="btn-sm" color="secondary" >{t("edit_button","Muokkaa")}</Button></>
                ) 
      }
    })
  }, [profitSummary, currentDate, onlySummary])

   

   



  
  // Filters
  
  const handleChange = ({target}, child) => {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value
    }))
   

  }

  const setFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  const setSelectFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value?.id > 0 ? value?.id : undefined
    }))
  }
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: filters[name],
      onChange: handleChange
    }),
    [filters, handleChange]
  );
  
  const handleStockMarketChange = ({target}) => {
    
    setFilters(s => ({
      ...s,
      stock_market: target.value
    }))
  }

  const handleInvestmentTypeChange = ({target}) => {
    if (target.value !== "10" ) {
      setFilters(s => ({
        ...s,
        related_model_type: target.value,
        stock_market: undefined,
        related_model_id: undefined
      }))

    }
    else {
    setFilters(s => ({
      ...s,
      related_model_type: target.value,
      related_model_id: undefined
    }))
  }
  }

  const handlePortfolioChange = ({target}) => {
    if (target.value !== "10" ) {
      setFilters(s => ({
        ...s,
        [target?.name]: target.value,
        related_model_type: undefined,
        stock_market: undefined,
        related_model_id: undefined
      }))

    }
    else {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value,
      related_model_type: undefined,
      related_model_id: undefined
    }))
  }
  }

  const handleSecurityChange = (e) => {
    console.log(e)
    const target = e?.target
    console.log(target)
   console.log(target?.related_model_type)
   const [related_model_type, related_model_id] = target.value.split('-');

    setFilters(s => ({
      ...s,
      related_model_type: related_model_type,
      related_model_id: related_model_id
    }))
  }

  const handlePrivFundChange = (e) => {
    console.log(e)
    const target = e?.target
    console.log(target)
   console.log(target?.related_model_type)
   const [related_model_type, related_model_id] = target.value.split('-');

    setFilters(s => ({
      ...s,
      related_model_type: related_model_type,
      related_model_id: related_model_id
    }))
  }
  const handleTimeRangeChange = ({target}, e) => {
    console.log(target)

    const {key, value, range} =JSON.parse(target?.value)
    console.log(range)
    const startDate = range? new Date(range?.start): null;
    const endDate = range?.end? new Date(range?.end): null;           
    setFilters(s => ({
      ...s,
      [target?.name]: target?.value,
      date_transaction__date__gte: startDate? startDate: null,
      date_transaction__date__lte: endDate? endDate: null,
    }))
  }

  const [modalEditOpen, setModalEditOpen] = useState(false)
  useEffect(() => {
    // if (portfolio == null || portfolio == undefined) {
    //   setModalEditOpen(true)
    // }

    if (portfoliosLoading) {
      setModalEditOpen(false)
    }
    else {
    setModalEditOpen(portfolios.length === 0 ? true : false)
    }
  }, [portfolios])

  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  
  const getStockSelect = () => {
    if (filters?.stock_market === "PRIVATE") {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("stock_private_obj")}
          label={t("purchase_order_edit_asset_stock", "Osake (Stock)")}
          setFieldValue={setSelectFieldValue}
        />
      )
    }
    else {
      return (
        <StockSelect
          {...getFormikInputProps("stock")}
          label={t("purchase_order_edit_asset_stock", "Osake (Stock)")}
          setFieldValue={setSelectFieldValue}
          showAll={true}
          setValueToSelect={true} 
        />
      )
    }
  }

  const getCurrencySelect = useCallback(() => {
    let default_currencys = ["EUR","USD","SEK"]

    //Add only selected currency to default currencys
    default_currencys = [...default_currencys, filters?.selected_currency]

    //ADD ALL PORTFOLIO CURRENCYS TO DEFAULT CURRENCYS
    // //map all portfolios and get all currencys
    // const all_currencys = portfolios.map((portfolio) => {
    //   return portfolio?.currency_label
    // })
    // //remove duplicates
    // const unique_currencys = [...new Set(all_currencys)];
    // //add unique currencys to default currencys
    // default_currencys = [...default_currencys, ...unique_currencys]

    return (
      <CurrencySelect 
      name='currency'
      value={filters.selected_currency}
      onChange={(event) => setFieldValue("selected_currency", event.target.value)}
      label={t("Valuutta")} 
      disabled={false}
      useOnlyList = {default_currencys}
      // useOnlyList = {filters?.selected_currency}
      />
    )
  }, [filters?.selected_currency, portfolios])



  const getInvestmentTypeSelect = useCallback(() => {
   return <InvestmentTypeSelect name="related_model_type" onChange={handleInvestmentTypeChange}   value={filters.related_model_type} />
  }, [filters?.related_model_type,  enums])

  const getSecuritySelect = useCallback((options) => {
   return <SecuritySelect name="security_select" onChange={handleSecurityChange} value={filters.related_model_type && filters.related_model_id ?`${filters.related_model_type}-${filters.related_model_id}`: ""} options={securityOptions} />
  }, [filters?.related_model_type, filters?.related_model_id, securityOptions])

  const getTimeRangeSelect = useCallback(() => {
    return<TimeRangeSelect name="time_range" onChange={handleTimeRangeChange} value={filters?.time_range} />
   }, [filters?.time_range])



   useEffect(() => {
  
  setExportButton( 
  <div>
  <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("excel")}>
    <i className="fas fa-file-excel"></i>&nbsp;
    {t("excel_export","Vie (Excel)")}
  </Button>
  <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("pdf")}>
    <i className="fas fa-file-pdf"></i>&nbsp;
    {t("pdf_export","Vie (PDF)")}
  </Button>
  </div>  
  )
    
  }, [filters, onlySummary])


  const getPortfolioSelect = useCallback(() => {
    return (
      <PortfolioSelect 
      name="selected_portfolios"
      label={t("Salkku")}
      setFieldValue={setFieldValue}
      multiple={true}
      placeholder={t("select_portfolio", "Valitse salkku")}
      onChange={handlePortfolioChange}
      value= {filters.selected_portfolios}
    />
    )
  }, [portfolio, filters?.selected_portfolios])
  
  return (
    
    <div className="sk_transaktions_list">
    <PageContainer>

      {openUpdateUp()}

      <div className="d-flex justify-content-between mb-3">

        <div className="d-flex text-right align-items-center">
        
   
          {/* 
          <Input label="Kurssipäivä" name="stock_date" type="date" value={currentDate} onChange={handleDateChange} />
          */}
        </div>
      </div>

      <Container fluid className="custom-no-padding  mt-3 m-0 mr-0 px-0" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>
        <Row className=" m-0 p-0">
            
          <Col className="m-0 p-0">
          {/* {JSON.stringify(filters)} */}
          <div className="d-flex flex-wrap ">
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px', maxWidth: '320px'}}>
                {getPortfolioSelect()}
                {/* <PortfolioSelect 
                  name="selected_portfolios"
                  label={t("Salkku")}
                  setFieldValue={setFieldValue}
                  multiple={true}
                  placeholder={t("select_portfolio", "Valitse salkku")}
                  onChange={handlePortfolioChange}
                  value= {filters.selected_portfolios}
                /> */}
              </div>

              {/* <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px',  maxWidth: '220px'}}>
                 {getInvestmentTypeSelect()}
              </div> */}
              {/* {
                filters?.related_model_type === 30 || filters?.related_model_type === "30" ? (
                <Row className="mt-3">
                  <Col>
                    <InvestmentTypeSelectSub name="fund_type" onChange={handlePrivFundChange} value={filters.priv_fund} showPrivate={false} showAll={false} icon=""  />
                  </Col>
                </Row>
                ) : null
              } */}

              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px',  maxWidth: '220px'}}>
                 {getSecuritySelect()}
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '130px', maxWidth:'140px'}}>
                {getTimeRangeSelect()}
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '100px', maxWidth: '130px'}}>
                <div>

                <label className="mb-1 pr-1 header-bold">{t("date_transaction_from", "Päivä alkaen")}</label>
                <DatePicker
                  className="form-control"
                  selected={filters?.date_transaction__date__gte}
                  name="date_transaction__date__gte"
                  onChange={
                    (date) => {
                      setFieldValue("date_transaction__date__gte", date)
                      setFieldValue("time_range", "")
                    }
                  }
                  showYearDropdown
                  dateFormat="d.M.yyyy"
                  isClearable={true}
                  placeholderText={t("Kaikki")}
                  
                  
                  />
                  </div>
              </div>
              <div className="d-flex justify-content-row align-items-end py-2 pr-2 mr-3" style={{minWidth: '100px', maxWidth: '130px'}}>
                <div>
                  <label className="mb-1 pr-1 header-bold">{t("date_transaction_to", "Päivä päättyen")}</label>
                  <DatePicker
                    className="form-control"
                    selected={filters?.date_transaction__date__lte}
                    name="date_transaction__date__lte"
                    onChange={
                      (date) => {
                        setFieldValue("date_transaction__date__lte", date)
                        setFieldValue("time_range", "")
                      }
                    }
                    showYearDropdown
                    dateFormat="d.M.yyyy"
                    isClearable={true}
                    placeholderText={t("Kaikki")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-row align-items-end py-2 pr-2 mr-3" style={{minWidth: '100px', maxWidth: '130px'}}>
                <div>
                    {getCurrencySelect()}
                </div>
              </div>


              <div className="d-flex justify-content-start align-items-end py-2 pr-2  ml-0 mr-3 ml-xxl-auto mr-xxl-0" style={{minWidth: '50px'}}>
                <Button name="profit_search_button" className="mt-2" color="secondary" onClick={() => getTransactions()}>{t("Hae")}</Button>
              </div>

          </div>

          </Col>
        </Row>
        </Container>
      
        <Container fluid className="custom-no-padding bg-lighter mt-3 m-0  mr-0 px-0" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>
        <Row className=" m-0 p-0">
          <Col className="m-0 p-0">
            <div className="d-flex flex-wrap ">
              <div className="d-flex justify-content-row align-items-end py-2 pr-2 mr-3" style={{minWidth: '100px', maxWidth: '130px'}}>
                <div className="d-flex justify-content-start align-items-center py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                    <Typography variant="medium" className=" mr-2 text-dark">{t("Yhteensä")}</Typography>
                    <Checkbox 
                    id="onlySummary"
                    name="onlySummary"
                    checked={onlySummary}
                    onChange={(e) => {setOnlySummary(e.target.checked)}}
                    className="zIndexZero"
                    />
                </div>
              </div>
              <div className="d-flex align-items-center ml-auto" style={{minWidth: '100px', }}>
                {/* <div className="p-2 ml-auto">
                  <Button color="secondary" size="sm" disabled={loadingExport} onClick={handleExcelExport}>
                    <i className="fas fa-file-excel"></i>&nbsp;
                    {t("excel_export","Vie (excel)")}
                  </Button>
                </div> */}
              </div>
            </div>
          </Col>
        </Row>
        </Container>


      <IncomeSummaryTable
        showId={false}
        checkboxes={false}
        headers={SummaryHeaders}
        headerColor={"light"}
        rows_sell={getSummaryTableRows("sell")}
        rows_buy={getSummaryTableRows("buy")}
        rows_summary={getSummaryTableRows("summary")}
        rows_dividend={getSummaryTableRows("dividend")}
        rows_other={getSummaryTableRows("other")}
        showDividend ={true}
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        showOther ={profitSummary?.other?.length > 0 ? true : false}

        />

      
      

      </PageContainer>
      
      <Typography  variant="small" className="mt-5 text-gray">
      * {t("batch_exchange_date","Erän vaihtopäivämäärä")}
    </Typography>

    <Typography  variant="small" className="mt-1 text-gray">
    ** {t("income_report_last_rate","Viimeisimmän kurssikirjauksen pvm tai mikäli ei ole kurssikirjausta, niin ostokirjauksen pvm ja hintana ostohinta")}
  </Typography>



      <Modal
      // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
      showHeader={false}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
      size="modal-lg"
    >
        <Info
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
      />
    </Modal>



      
      {/*JSON.stringify(stocksData)*/}
    </div>
  );
}

export default IncomeReportSummary;
