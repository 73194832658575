import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useStoreRehydrated  } from 'easy-peasy';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { httpGetEnums } from "services/enums";
import { Spinner } from "stories/components"
import { Container, Row, Col } from "stories/layout";
import { useAuthContext } from "contexts/AuthContext";
import { fi } from "date-fns/locale";



export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const isRehydrated = useStoreRehydrated(); // is persist data loaded
  const { t } = useTranslation();
  const [enums, setEnums] = useState();
  const { isAuthenticated, loading } = useAuthContext();

  // const setBasicDataObjects = useStoreActions((actions) => actions.stocks.setObjects);
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateStocks = useStoreActions((actions) => actions.privateStocks.getObjects);
  const getPrivateInvestmentFunds = useStoreActions((actions) => actions.privateInvestmentFunds.getObjects);
  const getLicenses = useStoreActions((actions) => actions.licenses.getLicenses);
  const getNewFeatures = useStoreActions((actions) => actions.newFeatures.getNewFeatures);
  const getFrontpageInfo = useStoreActions((actions) => actions.frontpageInfo.getFrontpageInfo);
  const getHelptexts = useStoreActions((actions) => actions.helptexts.getHelptexts);
  const getStockMarkets = useStoreActions((actions) => actions.stockMarkets.getStockMarkets);
  const getSectors = useStoreActions((actions) => actions.sectors.getSectors);
  const sectors = useStoreState((state) => state.sectors?.sectors);
  const getCompanies = useStoreActions((actions) => actions.companies.getCompanies);
  const getPortfoliosSummary = useStoreActions((actions) => actions.portfoliosSummary.getPortfoliosSummary);
  const getUnmadeDividendTransactions = useStoreActions((actions) => actions.unmadeDividendTransactions.getUnmadeDividendTransactions);
  const getUnmadeSplitTransactions = useStoreActions((actions) => actions.unmadeSplitTransactions.getUnmadeSplitTransactions);

  
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const licenses = useStoreState((state) => state.licenses?.licenses);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const setPortfolio = useStoreActions((actions) => actions.portfolio.setPortfolio);
  const companies = useStoreState((state) => state.companies?.companies)

  
  const getEnums = () => {
    httpGetEnums().then(response => {
      setEnums(response?.data);

    }, error => {
      console.log("getEnums error", error);
    })
  }
  
  const getBasicData = () => {
    const params = {
      stock_market: 'XHEL',
      ordering: 'name',
    }
    getBasicDataObjects(params)
  }

  // get portfolio name by id 
  const getPortfolioName = (id) => {
    const portfolio = portfolios && portfolios.reduce((result, portfolio) => {
      if (portfolio?.id === id) {
        result = portfolio
      }
      return result
    }, null)
    return portfolio?.name
  }

    // get portfolio currency by id 
  const getPortfolioCurrency = (id) => {
      const portfolio = portfolios && portfolios.reduce((result, portfolio) => {
        if (portfolio?.id === id) {
          result = portfolio
        }
        return result
      }, null)
      return portfolio?.currency_label
    }

  // get license name by id 
  const getLicenseName = (id) => {
    const license = licenses && licenses.reduce((result, license) => {
      if (license?.id === id) {
        result = license
      }
      return result
    }, null)
    return license?.name
  }

  // get company name by id
  const getCompanyInstance = (id) => {
    const company = companies && companies.reduce((result, company) => {
      if (company?.id === id) {
        result = company
      }
      return result
    }, null)
    return company
  }

  
  // Enum helpers
  
  const getTransactionType = (key) => {
    const transactionTypes = enums?.transactions?.transaction?.type
    if (transactionTypes && transactionTypes.length > 0) {
      const filteredObjects = transactionTypes.filter(o => o.key === key)
      if (filteredObjects && filteredObjects.length > 0) {
        return filteredObjects[0]
      }
    }
    return null;
  }

  const getSecurityType = (key) => {
    const transactionSecurityTypes = enums?.transactions?.transaction?.related_model_type
    if (transactionSecurityTypes && transactionSecurityTypes.length > 0) {
      const filteredObjects = transactionSecurityTypes.filter(o => o.key === key)
      if (filteredObjects && filteredObjects.length > 0) {
        return filteredObjects[0]
      }
    }
    return null;
  }

  const getSecurityTypeExtend = (key, type) => {
    const transactionSecurityTypes = enums?.transactions?.transaction?.related_model_type
    const subTypes = enums?.private_investment_funds?.private_investment_fund?.type

    if (transactionSecurityTypes && transactionSecurityTypes.length > 0) {
      let filteredObjects = null

      if (key == 30){
        filteredObjects = subTypes.filter(o => o.key === type)
      }
      else{
       filteredObjects = transactionSecurityTypes.filter(o => o.key === key)
      }
      if (filteredObjects && filteredObjects.length > 0) {
        return filteredObjects[0]
      }
    }
    return null;
  }

  //get stockmarket name by key
  const getStockMarketName = (key) => {
    const stockMarkets = enums?.stock?.basic_data?.stock_market
    //return stockmarket value using key
    if (stockMarkets && stockMarkets.length > 0) {  
      const filteredObjects = stockMarkets.filter(o => o.key === key)
      if (filteredObjects && filteredObjects.length > 0) {
        return filteredObjects[0].value
      }
    }
  }

  //get sector name by key
    const getSectorName = (key) => {
      
      //return stockmarket value using key
      if (sectors && sectors.length > 0) {  
        const filteredObjects = sectors.filter(o => o.id === key)
        if (filteredObjects && filteredObjects.length > 0) {
          return filteredObjects[0].name
        }
      }
    }

  
  //get user_type name by key
  const getUserTypeName = (key) => {
    const userTypes = enums?.accounts?.account_user?.user_type
    //return user_type value using key
    if (userTypes && userTypes.length > 0) {
      const filteredObjects = userTypes.filter(o => o.key === key)
      if (filteredObjects && filteredObjects.length > 0) {
        return filteredObjects[0].value
      }
    }

  }
  
  
  const getDerivativeType = (key) => {
    const derivativeTypes = enums?.options?.option?.derivative_type;
    return derivativeTypes && derivativeTypes.reduce((result, dType) => {
      if (dType?.key === key) {
        result = dType
      }
      return result
    }, null)
  }

  const getAccountTypeObject = (key) => {
    const accountTypes = enums?.accounts?.account?.type;
    return accountTypes && accountTypes.reduce((result, dType) => {
      if (dType?.key === key) {
        result = dType
      }
      return result
    }, null)
  }


  
  const getOptionsAssetType = (key) => {
    const assetTypes = enums?.options?.option?.asset_type;
    return assetTypes && assetTypes.reduce((result, assetType) => {
      if (assetType?.key === key) {
        result = assetType
      }
      return result
    }, null)
  }
  
  useEffect(() => {
    getEnums();
    getLicenses();
    getFrontpageInfo();
    if (isAuthenticated) {
      getBasicData();
      getPrivateStocks();
      getNewFeatures();
      getHelptexts();
      getCompanies();
      // getPortfoliosSummary();
      getUnmadeDividendTransactions()
      getUnmadeSplitTransactions()
      getPrivateInvestmentFunds();
      getStockMarkets();
      getSectors();


    }
  }, [isAuthenticated]);
  
  return isRehydrated ? (
      <AppContext.Provider
        value={{
          enums,
          getTransactionType,
          getOptionsAssetType,
          getDerivativeType,
          getPortfolioName,
          getPortfolioCurrency,
          getUserTypeName,
          getStockMarketName,
          getSectorName,
          getSecurityType,
          getSecurityTypeExtend,
          getLicenseName,
          getAccountTypeObject,
          getCompanyInstance,
        }}
      >
        {children}
      </AppContext.Provider>
    ) :            
      <Container className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status" className="text-secondary"/>
      </Container>
  
};

AppContext.defaultProps = {};
AppContext.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAppContext = () =>
  useContext(AppContext);
