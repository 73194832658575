import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAppContext }from "contexts/AppContext"
import { useNotification } from "stories/components/Notification"
import { useLocation } from "react-router-dom";

import http from 'services/api'

// Storybook
import { Row, Col, Card,CardBody, Container} from "stories/layout";
import { Input, DatePicker } from "stories/forms";
import { Typography, Button, Link, IconButton, Modal, Popover, PopoverInfo } from "stories/components"; 
import { TableAuto } from "stories/tables"

// App
import { httpGetTransactions, httpGetPrivateStockTransactions, httpGetTransactionsExcel, httpUploadTransactionsExcel } from "services/transactions"
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import TransactionTypeSelect from "components/Forms/TransactionTypeSelect/TransactionTypeSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"
// import InvestmentTypeSelect1 from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect1"
import PurchaseEdit from "views/app/PurchaseEdit/PurchaseEdit"
import OtherEdit from "views/app/OtherEdit/OtherEdit"
import SplitEdit from "views/app/SplitEdit/SplitEdit"
import ExchangeEdit from "views/app/ExchangeEdit/ExchangeEdit"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import AcceptTransactionsPractices from "components/Info/AcceptTransactionspractices"
import UnmadeSplitTransactionsList from 'views/app/UnmadeSplitTransactionsList/UnmadeSplitTransactionsList'
import UnmadeDividendTransactionsList from 'views/app/UnmadeDividendTransactionsList/UnmadeDividendTransactionsList'
import ExchangeTransactionTerms from 'views/app/ExchangeTransactionTerms/ExchangeTransactionTerms'
import ExchangeTransactionsCreate from 'views/app/ExchangeTransactionsCreate/ExchangeTransactionsCreate'
import ImportTransactions from 'views/app/ImportTransactions/ImportTransactions'
import ImportTransactionsNordnet from 'views/app/ImportTransactions/ImportTransactionsNordnet'
import ImportTransactionsGeneral from 'views/app/ImportTransactions/ImportTransactionsGeneral'
import DiarySummary from 'views/app/DiarySummary/DiarySummary'

import { useAuthContext } from "contexts/AuthContext"

import SaleEdit from "views/app/SaleEdit/SaleEdit"
import { getDate } from "services/datetime"
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"
import { set } from "date-fns";
import { filterEventStoreDefs } from "@fullcalendar/core";

import Info from "components/Info/Info.js";
import { setIn } from "formik";
import filter from "list.js/src/filter";
import { httpGetPortfoliosUnmadeDividendTransactions } from "services/portfolios";

const EXCEL_MAX_FILE_SIZE = 1024*1024*5;

const TransactionsList = ({match, history, setExportButton}) => {
  
  const { t } = useTranslation();
  const { notify } = useNotification();
  const { getTransactionType } = useAppContext()
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const myAccount = useStoreState((state) => state.account?.account);
  const myAccountLoading = useStoreState((state) => state.account?.loading);

  const unmadeSplitTransactions = useStoreState((state) => state.unmadeSplitTransactions?.unmadeSplitTransactions);
  const unmadeDividendTransactions = useStoreState((state) => state.unmadeDividendTransactions?.unmadeDividendTransactions);
  const getUnmadeDividendTransactions = useStoreActions((actions) => actions.unmadeDividendTransactions.getUnmadeDividendTransactions);
  const getUnmadeSplitTransactions = useStoreActions((actions) => actions.unmadeSplitTransactions.getUnmadeSplitTransactions);
  const portfoliosSummary = useStoreState((state) => state.portfoliosSummary?.portfolios_total_purchase_cost);
  const getPortfoliosSummary = useStoreActions((actions) => actions.portfoliosSummary.getPortfoliosSummary);

  const { myUser, getMyUser, loadingMyUser, accountUserType } = useAuthContext()

  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);

  
  const all_purchases = useStoreState((state) => state.purchases?.objects);
  // const setAllPurchases = useStoreActions((actions) => actions.purchases.setObjects);
  const all_count = useStoreState((state) => state.purchases?.count);
  // const setCount = useStoreActions((actions) => actions.purchases.setCount);

  //hold values in there because exchangeTransactionTerms and exchangeTransactionCreate are sibling. And from terms you pass values to create component.
  const [exchangeValues, setExchangeValues] = useState()

  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));

  const [purchases, setPurchases] = useState([])
  const [count, setCount] = useState(0)

  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfoliosLoading = useStoreState((state) => state.portfolio?.loading);
  const location = useLocation();

  
  const handleDateChange = event => {
    setCurrentDate(event.target.value)
  }
  
  // Table
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState([])

  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "-date_transaction",
    // stock_market: "XHEL",
    // portfolio: portfolio?.id,
  })

    //----------------------------UPDATE securities ----------------------------------------------------

    const getBasicDatas = () => {
      let param ={
        ...filters,
        stock_market:filters?.stock_market,
        related_model_type: filters?.related_model_type,
        all_related_model_types: true
      }

      if (filters?.related_model_type == "30" ){
        param ={...param, type: null}
      }

      //if related_model_type is not in [10, 20, 30, 40] then it is subs
      if (filters?.related_model_type !== "10" && filters?.related_model_type !== "20" && filters?.related_model_type !== "30" && filters?.related_model_type !== "40" && filters?.related_model_type !== "" && filters?.related_model_type !== null && filters?.related_model_type !== undefined ){
        param ={...param, related_model_type: "30", second_type: filters?.related_model_type}
      }

      getBasicDataObjects(param)
    }

    


    useEffect(() => {
      if (true) {
        // console.log("PRIVATE basic_data")
        // getPrivateBasicDataObjects()
  
        let param ={
          ...filters,
          stock_market:filters?.stock_market,
          related_model_type: filters?.related_model_type,
          all_related_model_types: true
        }
  
        if (filters?.related_model_type == "30" ){
          param ={...param, type: null}
        }

        //if related_model_type is not in [10, 20, 30, 40] then it is subs
        if (filters?.related_model_type !== "10" && filters?.related_model_type !== "20" && filters?.related_model_type !== "30" && filters?.related_model_type !== "40" && filters?.related_model_type !== "" && filters?.related_model_type !== null && filters?.related_model_type !== undefined ){
          param ={...param, related_model_type: "30", second_type: filters?.related_model_type}
        }

        getBasicDataObjects(param)
      }
      else {
      getBasicDataObjects({stock_market:filters?.stock_market, all_related_model_types: true})
      }
      // if (values)
      // setFieldValue('stock', null)
    }, [filters?.stock_market, 
      filters.related_model_type, 
      filters?.fund_type, 
      filters?.portfolio,
      filters?.type,
      modalEditExchangeEditOpen, 
      modalEditPurchaseOpen


    ])



  
  const getTransactions = () => {
    let params = {
      ...filters,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
    }
    if (filters?.stock?.id && filters?.stock?.id !== "") {
      // separete related_model_type and related_model_id from stock.id using "," first part is related_model_type and second is related_model_id
      //check if filters?.stock?.id is string 

      const related_model_type = filters?.stock?.id?.split(",")[0]
      const related_model_id = filters?.stock?.id?.split(",")[1]
      params = {
        ...params,
        related_model_type: related_model_type,
        related_model_id: related_model_id,
      }
      if (related_model_type !== "10" && related_model_type !== "20" && related_model_type !== "30" && related_model_type !== "40" ){
        params ={...params, related_model_type: 30}
      }
    }
    //if related_model_type is not in [10, 20, 30, 40] then it is subs
    if (filters?.related_model_type !== "10" && filters?.related_model_type !== "20" && filters?.related_model_type !== "30" && filters?.related_model_type !== "40" && filters?.related_model_type !== "" && filters?.related_model_type !== null && filters?.related_model_type !== undefined  ){
      params ={...params, related_model_type: "30", second_type: filters?.related_model_type}
    }

    setLoading(true)

    if(filters?.stock_market === "PRIVATE") {
      httpGetPrivateStockTransactions(params).then(response => {
        setPurchases(response?.data?.results)
        setCount(response?.data?.count)
        setLoading(false)
  
      })
     
    } else {

      httpGetTransactions(params).then(res => {

        setPurchases(res?.data?.results)
        setCount(res?.data?.count)

          setLoading(false)
        
      })

    }

    
  }

  useEffect(() => {
    // getUnmadeSplitTransactions()
    // getUnmadeDividendTransactions()

    if (location?.state?.newPurchase || location?.pathname === "/dashboard/transactions/new_purchase") {
      setModalEditPurchaseOpen(true)
    }
    if(location?.state?.newSale || location?.pathname === "/dashboard/transactions/new_sale"){
      setModalEditSaleOpen(true)
    }
    if(location?.state?.newExchange || location?.pathname === "/dashboard/transactions/new_exchange"){
      setModalEditExchangeOpen(true)
    }
  }, [location])
  


  useEffect(() => {
    setFilters(s => ({
      ...s,
      portfolio: portfolio?.id,
    }))
  }, [portfolio])
  
  useEffect(() => {
    
    if (filters?.portfolio == portfolio?.id && portfoliosLoading == false){

      getTransactions()
    }
  }, [filters])
  
  
  const headers = [
    { label: t("transactions_list_id", "ID"), key: "id", sortable: true, sortingKey: "id" },
    { label: t("transactions_list_batch_id", "Erän ID"), key: "batch_id", sortable: false,  },
    { label: t("transactions_list_date", "Tapaht. päivä"), key: "date_transaction", sortable: true, sortingKey: "date_transaction"  },
    { label: t("transactions_list_type", "Tyyppi"), key: "type", sortable: true, sortingKey: "type" },
    { label: t("transactions_list_name", "Nimi"), key: "stock__name", sortable: true, sortingKey: "stock__name"},
    { label: t("transactions_list_label", "Tunnus"), key: "stock__ticker_symbol", sortable: false},
    // { label: t("transactions_list_date", "old_id"), key: "inv1_event_number", sortable: true, sortingKey: "inv1_event_number"  },
    { label: t("transactions_list_stock_market", "Pörssi"), key: "stock_market", sortable: true, sortingKey: "stock_market" },
    { label: t("transactions_list_price", "Hinta/kpl"), key: "price", sortable: false},
    // { label: t("transactions_list_balance", "Saldo"), key: "balance"},
    { label: t("transactions_list_quantity", "Määrä"), key: "quantity", sortable: true, sortingKey: "quantity"},
    { label: t("transactions_list_price_total", "Hinta yhteensä"), key: "price_total", sortable: true, sortingKey: "price_total"},
    { label: t("transactions_list_commission_total", "Kulut"), key: "commission_total", sortable: true, sortingKey: "commission_total"},
    { label: t("transactions_list_value_total", "Yhteensä"), key: "value_total", sortable: true, sortingKey: "value_total"},
    { label: t("transactions_list_currency_label", "Valuutta"), key: "currency_label", sortable: true, sortingKey: "currency_label"},
    // { label: t("transactions_list_user", "Tehnyt"), key: "user__full_name", sortable: true, sortingKey: "user"},
    { label: t("transactions_list_portfolio", "Salkku"), key: "portfolio"},
    { label: t("transactions_list_batch", "Erästä jäljellä"), key: "batch", sortable: true, sortingKey: "batch"},
    { label: t("transactions_list_original_batch", "Erän alkup. koko*"), key: "original_batch", sortable: true, sortingKey: "original_batch"},
    { label: t("transactions_list_balance", "Balance"), key: "balance", sortable: true, sortingKey: "balance"},
    { label: t("transactions_list_comment", "Kommentti"), key: "notes"},
    { label: t("transactions_list_documents", "Liitteet"), key: "documents"},
    { label: t("transactions_list_purchase_cost", "Myynnin hankintahinta"), key: "purchase_price", },
    { label: t("transactions_list_purchase_commission", "Myynnin hankintakulu"), key: "purchase_commission", },
    // { label: t("transactions_list_balance", "Security id"), key: "related_model_id", },



    // { label: t("transactions_list_actions", ""), key: "actions"},
  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }

  const handleEditClick = (id, relatedModelType, tickerSymbol, type) => {
    // Your logic here...
    switch (type) {
      case 2:
        openModalEditPurchase(id, relatedModelType, tickerSymbol);
        break;
      case 3:
        openModalEditSale(id, relatedModelType, tickerSymbol);
        break;
      case 20:
        openModalEditSplit(id, relatedModelType, tickerSymbol);
        break;
      case 50:
        openModalEditOther(id, relatedModelType, tickerSymbol);
        break;
      default:
        openModalEditExchangeEdit(id, relatedModelType, tickerSymbol);
    }
  };
  
  const getTableRows = useCallback(() => {
    return purchases && purchases.map((row, index) => {
      row.typeOriginal = row?.type
      const transactionTypeValue = getTransactionType(row?.type)?.value
      let transactionTypeCell;
      if (transactionTypeValue === "Buy") {
        transactionTypeCell = (<span className="text-danger font-weight-bold">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Sell") {
        transactionTypeCell = (<span className="text-success font-weight-bold">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Split") {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Dividend") {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_30", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "DividendOwn") {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_32", transactionTypeValue)}</span>)
      }
      else if (row?.type === 50) {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_50", "MUU KULU/TUOTTO")}</span>)
      }
      else {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      }
      const stockNameCell = (
        // <span className="font-weight-bold">{row?.related_model?.name}</span>
        <Link className="text-dark">
          <span className="font-weight-bold" >
            {row?.related_model?.name}
          </span>
        </Link>
      )


      return {
        ...row,
        quantity:{
          content: thousandFormat(roundScaleDecimals(row?.quantity, 2)),
          className: "text-right"
        },
        price: {
          content: thousandFormat(roundScaleDecimals(Math.abs(row?.price), 2)),
          className: "text-right"
        },
        batch_id: row?.batch_id ? row?.batch_id : row?.id,
        original_batch: {
          content: thousandFormat(roundScaleDecimals(row?.original_batch_balance,2)),
          className: "text-right"
        },
        price_total: {
          content: thousandFormat(roundScaleDecimals(row?.price_total, 2)),
          className: "text-right"
        },
        commission_total: {
          content: thousandFormat(roundScaleDecimals(row?.commission_total, 2)),
          className: "text-right"
        },
        value_total: {
          content: thousandFormat(roundScaleDecimals(row?.value_total, 2)),
          className: "text-right"
        },
        batch: {
          content: thousandFormat(roundScaleDecimals(row?.batch_balance,2)),
          className: "text-right"
        },
        balance: {
          content: thousandFormat(roundScaleDecimals(row?.balance,2)),
          className: "text-right"
        },
        documents: row?.transaction_files?.length > 0 ? (
          <div className="d-flex justify-content-end">
          <i  className="fas fa-paperclip text-primary mr-auto mx-auto my-auto"></i>
          </div>
        ) : null,
        date_transaction: (
          <>
          {getDate(row?.date_transaction) + " "}
          {row?.original_purchase_date?<span className="text-gray "> ({getDate(row?.original_purchase_date)})**</span>:null}
          </>
        )
        ,
        portfolio: row?.portfolio?.name,
        type: transactionTypeCell,
        stock__ticker_symbol: row?.related_model?.ticker_symbol ? row?.related_model?.ticker_symbol : row?.related_model?.symbol,
        stock__name: stockNameCell,
        stock_market: row?.stock_market,
        user__full_name: row?.user?.full_name,
        actions: row?.type === 2 ? (
            <Button className="btn-sm" color="secondary" onClick={() => openModalEditPurchase(row?.id, row?.related_model_type, row?.related_model?.ticker_symbol)}>{t("edit_button_transactions","Näytä")}</Button>
          ) : row?.type==3? (
            <>
           
            <Button className="btn-sm" color="secondary" onClick={() => openModalEditSale(row?.id, row?.related_model_type, row?.related_model?.ticker_symbol)}>{t("edit_button_transactions","Näytä")}</Button>
            </>
          ) :row?.type==20? (
            <>
           
            <Button className="btn-sm" color="secondary" onClick={() => openModalEditSplit(row?.id, row?.related_model_type, row?.related_model?.ticker_symbol)}>{t("edit_button_transactions","Näytä")}</Button>
            </>
          ):row?.type==50? 
          (
            <>
            <Button className="btn-sm" color="success" onClick={() => openModalEditOther(row?.id, row?.related_model_type, row?.related_model?.ticker_symbol)}>{t("edit_button_transactions","Näytä")}</Button>
            </>
          ):(
            <>
           
            <Button className="btn-sm" color="secondary" onClick={() => openModalEditExchangeEdit(row?.id, row?.related_model_type, row?.related_model?.ticker_symbol)}>{t("edit_button_transactions","Näytä")}</Button>
            </>
          )
      }
    })
  }, [purchases, currentDate, loading])

  // Modal: New/Edit Sale
  
  const [modalEditExchangeOpen, setModalEditExchangeOpen] = useState(false)
  const [modalEditExchangeId, setModalEditExchangeId] = useState()
  
  const toggleModalEditExchange = () => {
    setModalEditExchangeOpen(s => !s);
  }
  
  const openModalEditExchange = (id, related_model_type, ticker_symbol) => {
    setModalEditExchangeOpen(true)
    setModalEditExchangeId(id);
    setInvestmentType(related_model_type)
    setTickerSymbol(ticker_symbol)
  }
  
  const modalEditExchangeResolve = () => {
    setModalEditExchangeOpen(false);
    setModalEditExchangeId(null);
    getBasicDatas()
    getUnmadeDividendTransactions()
    getUnmadeSplitTransactions()

    // getTransactions()
  //  getUnmadeExchangeTransactions()

    
  }
  
  const modalEditExchangeReject = () => {
    setModalEditExchangeOpen(false);
    setModalEditExchangeId(null);
    getBasicDatas()

  }
  

    // Modal: New/Edit Sale
  
    const [modalEditExchangeCreateOpen, setModalEditExchangeCreateOpen] = useState(false)
    const [modalEditExchangeCreateId, setModalEditExchangeCreateId] = useState()
    
    const toggleModalEditExchangeCreate = () => {
      setModalEditExchangeCreateOpen(s => !s);
    }
    
    const openModalEditExchangeCreate = (id, related_model_type, ticker_symbol) => {
      setModalEditExchangeCreateOpen(true)
      setModalEditExchangeCreateId(id);
    }
    
    const modalEditExchangeCreateResolve = () => {
      setModalEditExchangeCreateOpen(false);
      setModalEditExchangeCreateId(null);
      getTransactions()
    //  getUnmadeExchangeTransactions()
  
      
    }
    
    const modalEditExchangeCreateReject = () => {
      setModalEditExchangeCreateOpen(false);
      setModalEditExchangeCreateId(null);
    }
  // Modal: New/Edit Purchase
  
  const [modalEditPurchaseOpen, setModalEditPurchaseOpen] = useState(false)
  const [modalEditPurchaseId, setModalEditPurchaseId] = useState()
  const [investmentType, setInvestmentType] = useState("")
  const [tickerSymbol, setTickerSymbol] = useState('')
  
  const toggleModalEditPurchase = () => {
    setModalEditPurchaseOpen(s => !s);
  }
  
  const openModalEditPurchase = (id, related_model_type, ticker_symbol) => {
    setModalEditPurchaseOpen(true)
    setModalEditPurchaseId(id);
    setInvestmentType(related_model_type)
    setTickerSymbol(ticker_symbol)
   
  }
  
  const modalEditPurchaseResolve = () => {
    setModalEditPurchaseOpen(false);
    setModalEditPurchaseId(null);
    getUnmadeSplitTransactions()
    getUnmadeDividendTransactions()
    getPortfoliosSummary()
    getBasicDatas()
    
    // getTransactions()
  }
  
  const modalEditPurchaseReject = () => {
    setModalEditPurchaseOpen(false);
    setModalEditPurchaseId(null);
    getBasicDatas()

  }
  
  // Modal: New/Edit Sale
  
  const [modalEditSaleOpen, setModalEditSaleOpen] = useState(false)
  const [modalEditSaleId, setModalEditSaleId] = useState()
  
  const toggleModalEditSale = () => {
    setModalEditSaleOpen(s => !s);
  }
  
  const openModalEditSale = (id, related_model_type, ticker_symbol) => {
    setModalEditSaleOpen(true)
    setModalEditSaleId(id);
    setInvestmentType(related_model_type)
    setTickerSymbol(ticker_symbol)
  }
  
  const modalEditSaleResolve = () => {
    setModalEditSaleOpen(false);
    setModalEditSaleId(null);
    getTransactions()
    getUnmadeSplitTransactions()
    getUnmadeDividendTransactions()
    getBasicDatas()

    
  }
  
  const modalEditSaleReject = () => {
    setModalEditSaleOpen(false);
    setModalEditSaleId(null);
    getBasicDatas()

  }

  // Modal: New/Edit Exchange

  const [modalEditExchangeEditOpen, setModalEditExchangeEditOpen] = useState(false)
  const [modalEditExchangeEditId, setModalEditExchangeEditId] = useState()
  
  const toggleModalEditExchangeEdit = () => {
    setModalEditExchangeEditOpen(s => !s);
  }
  
  const openModalEditExchangeEdit = (id, related_model_type, ticker_symbol) => {
    setModalEditExchangeEditOpen(true)
    setModalEditExchangeEditId(id);
    setInvestmentType(related_model_type)
    setTickerSymbol(ticker_symbol)
  }
  
  const modalEditExchangeEditResolve = () => {
    setModalEditExchangeEditOpen(false);
    setModalEditExchangeEditId(null);
    getTransactions()
    getBasicDatas()
    getUnmadeDividendTransactions()

    
  }
  
  const modalEditExchangeEditReject = () => {
    setModalEditExchangeEditOpen(false);
    setModalEditExchangeEditId(null);
    getBasicDatas()

  }

   // Modal: New/Edit Sale
  
   const [modalEditSplitOpen, setModalEditSplitOpen] = useState(false)
   const [modalEditSplitId, setModalEditSplitId] = useState()
   
   const toggleModalEditSplit = () => {
     setModalEditSplitOpen(s => !s);
   }
   
   const openModalEditSplit = (id, related_model_type, ticker_symbol) => {
     setModalEditSplitOpen(true)
     setModalEditSplitId(id);
     setInvestmentType(related_model_type)
     setTickerSymbol(ticker_symbol)
   }
   
   const modalEditSplitResolve = () => {
     setModalEditSplitOpen(false);
     setModalEditSplitId(null);
     getTransactions()
    getUnmadeSplitTransactions()
    // getUnmadeDividendTransactions()
    getBasicDatas()


     
   }
   
   const modalEditSplitReject = () => {
     setModalEditSplitOpen(false);
     setModalEditSplitId(null);
    getBasicDatas()

   }

  //OTHER EDIT
  const [modalEditOtherOpen, setModalEditOtherOpen] = useState(false)
  const [modalEditOtherId, setModalEditOtherId] = useState()

  
  const toggleModalEditOther = () => {
    setModalEditOtherOpen(s => !s);
  }
  
  const openModalEditOther = (id, related_model_type, ticker_symbol) => {
    setModalEditOtherOpen(true)
    setModalEditOtherId(id);
    setInvestmentType(related_model_type)
    setTickerSymbol(ticker_symbol)
   
  }
  
  const modalEditOtherResolve = () => {
    setModalEditOtherOpen(false);
    setModalEditOtherId(null);
    getUnmadeSplitTransactions()
    getUnmadeDividendTransactions()
    getPortfoliosSummary()
    getBasicDatas()
    
    // getTransactions()
  }
  
  const modalEditOtherReject = () => {
    setModalEditOtherOpen(false);
    setModalEditOtherId(null);
    getBasicDatas()

  }



    // Modal: import

    const [modalEditImportNordeaOpen, setModalEditImportNordeaOpen] = useState(false)
    const [modalEditImportNordeaId, setModalEditImportNordeaId] = useState()
    
    const toggleModalEditImportNordea = () => {
      setModalEditImportNordeaOpen(s => !s);
    }
    
    const openModalEditImportNordea = (id, related_model_type, ticker_symbol) => {
      setModalEditImportNordeaOpen(true)
      setModalEditImportNordeaId(id);
      setInvestmentType(related_model_type)
      setTickerSymbol(ticker_symbol)
    }
    
    const modalEditImportNordeaResolve = () => {
      setModalEditImportNordeaOpen(false);
      setModalEditImportNordeaId(null);
      getTransactions()
      getBasicDatas()

    //  getUnmadeImportTransactions()
      
    }
    
    const modalEditImportNordeaReject = () => {
      setModalEditImportNordeaOpen(false);
      setModalEditImportNordeaId(null);
      getTransactions()
      getBasicDatas()

    
    }

    // Modal: import
  
      const [modalEditImportOpen, setModalEditImportOpen] = useState(false)
      const [modalEditImportId, setModalEditImportId] = useState()
      
      const toggleModalEditImport = () => {
        setModalEditImportOpen(s => !s);
      }
      
      const openModalEditImport = (id, related_model_type, ticker_symbol) => {
        setModalEditImportOpen(true)
        setModalEditImportId(id);
        setInvestmentType(related_model_type)
        setTickerSymbol(ticker_symbol)
      }
      
      const modalEditImportResolve = () => {
        setModalEditImportOpen(false);
        setModalEditImportId(null);
        getTransactions()
        getBasicDatas()

      //  getUnmadeImportTransactions()
        
      }
      
      const modalEditImportReject = () => {
        setModalEditImportOpen(false);
        setModalEditImportId(null);
        getTransactions()
        getBasicDatas()

       
      }

      // Modal: New/Edit Sale
  
    const [modalEditAcceptPracticesOpen, setModalEditAcceptPracticesOpen] = useState(false)
    const [modalEditAcceptPracticesId, setModalEditAcceptPracticesId] = useState()

    useEffect(() => {
      // if (portfolio == null || portfolio == undefined) {
      //   setModalEditOpen(true)
      // }
      if (portfoliosLoading || loadingMyUser) {
        setModalEditAcceptPracticesOpen(false)
      }
  
      else if (myUser.accept_transaction_practice === false && portfolios.length > 0) {
        setModalEditAcceptPracticesOpen(true)
      }

    }, [myUser, portfolios, loadingMyUser])
    
    const toggleModalEditAcceptPractices = () => {
      setModalEditAcceptPracticesOpen(s => !s);
    }
    
    const openModalEditAcceptPractices = (id, related_model_type, ticker_symbol) => {
      setModalEditAcceptPracticesOpen(true)
      setModalEditAcceptPracticesId(id);

    }
    
    const modalEditAcceptPracticesResolve = () => {
      setModalEditAcceptPracticesOpen(false);
      setModalEditAcceptPracticesId(null);
      getMyUser()
      
      
    }
    
    const modalEditAcceptPracticesReject = () => {
      setModalEditAcceptPracticesOpen(false);
      setModalEditAcceptPracticesId(null);
    }
  
  // Filters
  
  const handleChange = ({target}, child) => {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value
    }))
  }
  const setFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  const setSelectFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: filters[name],
      onChange: handleChange
    }),
    [filters, handleChange]
  );
  
  const handleStockMarketChange = ({target}) => {
    
    setFilters(s => ({
      ...s,
      stock_market: target.value,
      stock: null
    }))
  }

  const handleInvestmentTypeChange = ({target}) => {
    if (target.value !== "10" ) {
      setFilters(s => ({
        ...s,
        related_model_type: target.value,
        stock_market: undefined,
        stock: null
      }))

    }
    else {
    setFilters(s => ({
      ...s,
      related_model_type: target.value,
      stock: null,
    }))
  }
  }

  const [modalEditOpen, setModalEditOpen] = useState(false)
  useEffect(() => {
    // if (portfolio == null || portfolio == undefined) {
    //   setModalEditOpen(true)
    // }

    if (portfoliosLoading) {
      setModalEditOpen(false)
    }
    else {
    setModalEditOpen(portfolios.length === 0 ? true : false)
    }
  }, [portfolios])

  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  
  const getStockSelect = useCallback(() => {
    if (filters?.stock_market === "PRIVATE") {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("stock_private_obj")}
          label={t("purchase_order_edit_asset_stock", "Osake (Stock)")}
          setFieldValue={setSelectFieldValue}
        />
      )
    }
    else {
      return (
        <StockSelect
          style={{minWidth: '250px!important'}}
          className="select_multi_select_large"
          {...getFormikInputProps("stock")}
          label={t("purchase_order_edit_asset_stock", "Arvopaperi")}
          setFieldValue={setSelectFieldValue}
          // value={filters?.stock}
          setFieldValueReturnId={false}
          ratesView={false}
          focused={true}
          showAll={true}
          setValueToSelect={true} 
          
        />
      )
    }
  }, [filters?.related_model_type, filters?.stock_market, filters?.stock, filters?.stock_private_obj])




  


  const getInvestmentTypeSelect = useCallback(() => {
   return <InvestmentTypeSelect name="related_model_type" onChange={handleInvestmentTypeChange} showSubsOptions={true} value={filters.related_model_type} />
  }, [filters?.related_model_type, myAccount])
  
  // Excel export BEGIN
  
  const [loadingExport, setLoadingExport] = useState(false)

  const handleExcelExport = () => {
    
    let params = {
      ...filters,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      date_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,

      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      // ordering: '-id',
      client_timezone_offset: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    if (filters?.stock?.id && filters?.stock?.id !== "") {
      // separete related_model_type and related_model_id from stock.id using "," first part is related_model_type and second is related_model_id
      //check if filters?.stock?.id is string 

      const related_model_type = filters?.stock?.id?.split(",")[0]
      const related_model_id = filters?.stock?.id?.split(",")[1]
      params = {
        ...params,
        related_model_type: related_model_type,
        related_model_id: related_model_id,
      }
      if (related_model_type !== "10" && related_model_type !== "20" && related_model_type !== "30" && related_model_type !== "40" ){
        params ={...params, related_model_type: 30}
      }
    }
    //if related_model_type is not in [10, 20, 30, 40] then it is subs
    if (filters?.related_model_type !== "10" && filters?.related_model_type !== "20" && filters?.related_model_type !== "30" && filters?.related_model_type !== "40" && filters?.related_model_type !== "" && filters?.related_model_type !== null && filters?.related_model_type !== undefined  ){
      params ={...params, related_model_type: "30", second_type: filters?.related_model_type}
    }
    
    let fileName = "investime_transactions_export.xlsx";
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    
    setLoadingExport(true)
    notify({ title:t("Viedään Exceliin..."), icon: "fas fa-file-export"})
    httpGetTransactionsExcel(params).then(response => {
      let file = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      let fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = fileName;
      a.click();
    })
    .finally(() => {
      setLoadingExport(false)
    })
  }
  
  // Excel export END
  
  // Excel import BEGIN
  const fileInputRef = useRef(null);
  const [excelFile, setExcelFile] = useState()
  const [loadingImport, setLoadingImport] = useState(false)
  
  useEffect(() => {
    if (excelFile) {
      handleUploadExcelFile()
    }
  }, [excelFile])
  
  const handleImportExcelButtonClick = () => {
    fileInputRef.current.click();
  };
  
  const handleImportFileChange = (e) => {
    setExcelFile(e.target.files[0])
  }
  
  const handleUploadExcelFile = () => {
    if (!excelFile) {
      return;
    }
    let formData = new FormData();
    formData.append('file', excelFile);
    formData.append('portfolio', portfolio?.id);
    
    setLoadingImport(true)
    notify({ title:t("Tuodaan Excelistä..."), icon: "fas fa-file-import"})
    httpUploadTransactionsExcel(formData).then(res => {
      setExcelFile(null)
      fileInputRef.current.value = "";
      notify({ title:t("Excel tuotu onnistuneesti"), type: "success", icon: "fas fa-check"})
      getTransactions()
    }).finally(() => {
      setLoadingImport(false)
    })
  }

  const split_count = useCallback(() => {
    let data = unmadeSplitTransactions;
    if (myAccount.split_handling_only_active_portfolio == true){
      data = unmadeSplitTransactions.filter(o => o.batch.portfolio == portfolio.id)
    }
    return data.length
  }
  , [unmadeSplitTransactions, myAccount, portfolio])
  
  // Excel import END

  const getImportButton = useCallback(() => {
    return(

      myAccount?.import_from_old_investime && (accountUserType=="basic" || accountUserType=="admin") && (
        <>
        <div className="p-2">
            <Button className="btn-sm" color="secondary" onClick={() => openModalEditImport(null)}>{t("imort_button","Tuo excel vahnasta Inv.(Beta)")}</Button>
            
          </div>
            <div className="p-2">
            <Button className="btn-sm" color="secondary" onClick={() => openModalEditImportNordea(null)}>{t("imort_button_v2","Tuo excel (Beta)")}</Button>
        </div>
      </>
    )
    )
  }, [myAccount, myAccountLoading ])



  useEffect(() => {
  
    setExportButton(               
      <div className="d-flex flex-row-reverse">
         { getImportButton() }


        <div className="p-2 pr-0">
          <Button color="secondary" size="sm" disabled={loadingExport} onClick={handleExcelExport}>
            <i className="fas fa-file-excel"></i>&nbsp;
            {t("excel_export","Vie (Excel)")}
          </Button>
        </div>
      </div>
    )
    }, [filters,])
    

  
  return (
    <div className="sk_transaktions_list">
      <div className=" mb-3">
      <div className="row">
          <div className="col-sm-8 ">
            {
              (accountUserType=="basic" || accountUserType=="admin") && (
                <div className="d-flex-column">
              <Button className="ml-0 mb-2" name="add_buy_transaction" color="secondary" disabled={accountUserType=="read_only"} onClick={() => openModalEditPurchase(null)}>{t("transactions_list_new_purchase", "Uusi ostokirjaus")}</Button>
              <Button className="ml-0 mb-2" name="add_sell_transaction" color="secondary" onClick={() => openModalEditSale(null)}>{t("transactions_list_new_sale", "Uusi myyntikirjaus")}</Button>
              <Button className="ml-0 mb-2" name="add_exchange_transaction" color="secondary" onClick={() => openModalEditExchange(null)}>{t("transactions_list_new_exchange", "Vaihdot")}</Button>   
              <Button className="ml-0 mb-2" name="add_exchange_transaction" color="secondary" onClick={() => openModalEditOther(null)}>{t("transactions_list_other", "Muu kulu / tuotto")}</Button>   
            </div>
              )  
            }
          </div>
          <div className="col-sm-4 justify-content-md-right">
          <PortfolioSelectStore icon="fas fa-suitcase" iconColor="text-secondary" showAll={true} value={filters?.portfolio} disabled={true}/>       
          </div>
        </div>

      </div>
      { (accountUserType=="basic" || accountUserType=="admin")  && (
        <div className="mb-0">
            {unmadeSplitTransactions?.[0]?.batch && (
              <>
              {split_count() > 0 && (
                <Card className="shadow-sm-stronger ">
                <CardBody className="p-2">
                  {
                    <UnmadeSplitTransactionsList getTransactions={getTransactions} />
                  }

                </CardBody>
              </Card>
              )}
                  </>
            )}
              {(unmadeDividendTransactions?.[0]?.batch && myAccount.hide_dividends_handling == false) && (
              <Card className="shadow-sm-stronger">
                <CardBody className="p-2">

                  {
                      <UnmadeDividendTransactionsList getTransactions={getTransactions} />
                  }
                </CardBody>
              </Card>
            )}

          </div>
        )
      }


      <Container fluid className="custom-no-padding  mt-3 m-0 mr-0 px-0" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>
        <Row className=" m-0 p-0">
            
          <Col className="m-0 p-0">

          <div className="d-flex flex-wrap ">
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '250px'}}>
                {getStockSelect()}
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '250px',  maxWidth: '320px'}}>
                {myAccount?.license && getInvestmentTypeSelect()}
              </div>
              
              {filters?.related_model_type == 10 &&
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px', maxWidth:'140px'}}>
                 <StockMarketSelect onChange={handleStockMarketChange} value={filters?.stock_market} showPrivate={false} />
              </div>
              }
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px', maxWidth:'140px'}}>
                <TransactionTypeSelect name="type" onChange={handleChange} value={filters?.type} />
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '140px', maxWidth: '150px'}}>
                <div>
                  <label className="mb-1 pr-1 header-bold">{t("date_transaction_from", "Päivä alkaen")}</label>
                  <DatePicker
                    className="form-control"
                    selected={filters?.date_transaction__date__gte}
                    name="date_transaction__date__gte"
                    onChange={(date) => setFieldValue("date_transaction__date__gte", date)}
                    showYearDropdown
                    dateFormat="d.M.yyyy"
                    isClearable={true}
                    placeholderText={t("Kaikki")}

                  />
                  {/*
                    <Input label={t("date_transaction_from", "Päivä alkaen")} name="date_transaction__date__gte" type="date" value={filters?.date_transaction__date__gte} onChange={handleChange} />
                  */}
                </div>
              </div>
              <div className="d-flex justify-content-row align-items-end py-2 pr-2 mr-3" style={{minWidth: '140px', maxWidth: '150px'}}>
                <div >
                  <label className="mb-1 pr-1 header-bold">{t("date_transaction_to", "Päivä päättyen")}</label>
                  <DatePicker
                    className="form-control"
                    selected={filters?.date_transaction__date__lte}
                    name="date_transaction__date__lte"
                    onChange={(date) => setFieldValue("date_transaction__date__lte", date)}
                    showYearDropdown
                    dateFormat="d.M.yyyy"
                    isClearable={true}
                    placeholderText={t("Kaikki")}

                  
                  />
                </div>
              </div>


          </div>

          </Col>
        </Row>
      </Container>
      


      




      
      <TableAuto
        showRowNumber={false}
        showId={false}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        filters={filters}
        setFilters={setFilters}
        checked={checked}
        setChecked={setChecked}
        name="transactionsList_table"
        customOnClick={handleEditClick}
        
      />
      
      <div className="d-flex justify-content-between">
        <div>
          <Typography variant="small">{t("transactions_list_transactions_total","Kirjauksia yhteensä")} {count} { t("kpl")}</Typography>
        </div>
        
        {/* { appConfig.isProduction === false ? ( */}
        { false ? (
        <div>
          <div className="d-flex flex-row-reverse">
          
               <div className="p-2">
              <Button className="btn-sm" color="secondary" onClick={() => openModalEditImport(null)}>{t("imort_button","Import")}</Button>
              </div>
              {/*
              <div className="p-2">
              <Button color="secondary" size="sm" disabled={loadingImport} onClick={handleImportExcelButtonClick}>
              <i className="fas fa-file-excel"></i>&nbsp;
              {t("Import from Excel")}
            </Button>  
            
              
              
              
            </div>
        
          */}
          <div className="p-2">
            <Button color="secondary" size="sm" disabled={loadingExport} onClick={handleExcelExport}>
              <i className="fas fa-file-excel"></i>&nbsp;
              {t("Export to Excel")}
            </Button>
          </div>
          </div>
        </div>
        ) : null }
        
      </div>
      {/* <DiarySummary filters={filters}/> */}


      <Modal
        // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
        isOpen={modalEditPurchaseOpen}
        toggleModal={toggleModalEditPurchase}
        size="modal-xl"
        showHeader={false}
        classNameBody="p-0 padding-0"
      >
        <PurchaseEdit
          objectId={modalEditPurchaseId}
          handleResolve={modalEditPurchaseResolve}
          handleReject={modalEditPurchaseReject}
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
          transaction_count = {all_count}
          portfoliosSummary={portfoliosSummary}
        />
      </Modal>

      <Modal
        // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
        isOpen={modalEditOtherOpen}
        toggleModal={toggleModalEditOther}
        size="modal-xl"
        showHeader={false}
        classNameBody="p-0 padding-0"
      >
        <OtherEdit
          objectId={modalEditOtherId}
          handleResolve={modalEditOtherResolve}
          handleReject={modalEditOtherReject}
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
          transaction_count = {all_count}
          portfoliosSummary={portfoliosSummary}
        />
      </Modal>
      
      <Modal
        // title={modalEditSaleId > 0 ? t("Muokkaa myyntikirjausta") : t("Uusi myyntikirjaus")}
        showHeader={false}
        isOpen={modalEditSaleOpen}
        toggleModal={toggleModalEditSale}
        size="modal-xl"
        classNameBody="p-0 padding-0"
      >
        <SaleEdit
          objectId={modalEditSaleId}
          handleResolve={modalEditSaleResolve}
          handleReject={modalEditSaleReject}
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
          transaction_count = {all_count}

        />
      </Modal>

      <Modal
        // title={modalEditSaleId > 0 ? t("Muokkaa myyntikirjausta") : t("Uusi myyntikirjaus")}
        showHeader={false}
        isOpen={modalEditExchangeOpen}
        toggleModal={toggleModalEditExchange}
        size="modal-xl"
        classNameBody="p-0 padding-0"

      >
        <ExchangeTransactionTerms
          objectId={modalEditExchangeId}
          handleResolve={modalEditExchangeResolve}
          openCreate={openModalEditExchangeCreate}
          handleReject={modalEditExchangeReject}
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
          setExchangeValues={setExchangeValues}
          exchangeValues={exchangeValues}
        />
      </Modal>

      

      <Modal
        // title={modalEditSaleId > 0 ? t("Muokkaa myyntikirjausta") : t("Uusi myyntikirjaus")}
        showHeader={false}
        isOpen={modalEditSplitOpen}
        toggleModal={toggleModalEditSplit}
        size="modal-xl"
        classNameBody="p-0 padding-0"

      >
        <SplitEdit
          objectId={modalEditSplitId}
          handleResolve={modalEditSplitResolve}
          handleReject={modalEditSplitReject}
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
        />
      </Modal>

      <Modal
        title={t("Excel tuonti")}
        showHeader={true}
        isOpen={modalEditImportOpen}
        toggleModal={toggleModalEditImport}
        closeModal={modalEditImportReject}
        size="modal-xl"
      >
        <ImportTransactions
          objectId={modalEditImportId}
          handleResolve={modalEditImportResolve}
          handleReject={modalEditImportReject}
         
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
        />
      </Modal>

      <Modal
        title={t("Excel tuonti")}
        showHeader={true}
        isOpen={modalEditImportNordeaOpen}
        // isOpen={true}
        toggleModal={toggleModalEditImportNordea}
        closeModal={modalEditImportNordeaReject}
        size="modal-xl"
      >
        {/* <ImportTransactionsNordnet
          objectId={modalEditImportNordeaId}
          handleResolve={modalEditImportNordeaResolve}
          handleReject={modalEditImportNordeaReject}
         
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
        /> */}
        <ImportTransactionsGeneral
          objectId={modalEditImportNordeaId}
          handleResolve={modalEditImportNordeaResolve}
          handleReject={modalEditImportNordeaReject}
         
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
        />
      </Modal>
      <Modal
        // title={modalEditSaleId > 0 ? t("Muokkaa myyntikirjausta") : t("Uusi myyntikirjaus")}
        showHeader={false}
        isOpen={modalEditExchangeEditOpen}
        toggleModal={toggleModalEditExchangeEdit}
        size="modal-xl"
        classNameBody="p-0 padding-0"

      >
        <ExchangeEdit
          objectId={modalEditExchangeEditId}
          handleResolve={modalEditExchangeEditResolve}
          handleReject={modalEditExchangeEditReject}
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
        />
      </Modal>

      <Modal
      // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
      showHeader={false}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
      size="modal-lg"
    >
        <Info
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
      />
    </Modal>


    <Modal
    // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
    isOpen={modalEditAcceptPracticesOpen}
    toggleModal={toggleModalEditAcceptPractices}
    size="modal-lg"
    showHeader={false}
    classNameBody="p-0 padding-0"

  >
      <AcceptTransactionsPractices
      objectId={modalEditAcceptPracticesId}
      handleResolve={modalEditAcceptPracticesResolve}
      handleReject={modalEditAcceptPracticesReject}
    />
  </Modal>

  <Modal
  // title={modalEditSaleId > 0 ? t("Muokkaa myyntikirjausta") : t("Uusi myyntikirjaus")}
  showHeader={false}
  isOpen={modalEditExchangeCreateOpen}
  toggleModal={toggleModalEditExchangeCreate}
  size="modal-xl"
>
  <ExchangeTransactionsCreate
    objectId={modalEditExchangeCreateId}
    handleResolve={modalEditExchangeCreateResolve}
    handleReject={modalEditExchangeCreateReject}
    defaultInvestmentType={investmentType}
    setFilters={setFilters}
    exchangeValues={exchangeValues}

  />
</Modal>

      {/*JSON.stringify(stocksData)*/}
    </div>
  );
}

export default TransactionsList;
